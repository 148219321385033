import { sendHeartbeat } from 'src/core/utils';
import { register } from 'src/brandless/ujs';

register('meta[name="current-user"]', ({ element }) => {
  const currentUserMeta = document.querySelector('meta[name="current-user"]');
  const pollIntervalSeconds = 5;
  const pollInterval = pollIntervalSeconds * 1000;
  let reauthenticationTimeout;
  let checkSessionTimeoutInterval;

  if (!currentUserMeta) {
    return;
  }

  let allowHeartbeat = true;

  function resetIdleTimer(): void {
    // Prevents from spamming server requests
    if (!allowHeartbeat) {
      console.log('Timeout currently in progress');
      return;
    } else {
      sendHeartbeat();

      if (reauthenticationTimeout) {
        clearTimeout(reauthenticationTimeout);
        checkSessionTimeoutInterval = setInterval(
          checkSessionTimeout,
          pollInterval
        );
      }
    }

    allowHeartbeat = false;

    const allowHeartbeatTimeout = setTimeout(() => {
      allowHeartbeat = true;
      clearTimeout(allowHeartbeatTimeout);
    }, pollInterval);
  }

  function redirectToLogin(): void {
    Turbolinks.clearCache();
    window.location.reload();
  }

  checkSessionTimeoutInterval = setInterval(checkSessionTimeout, pollInterval);

  function checkSessionTimeout(): void {
    fetch('/check_session_timeout')
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
      })
      .then((result: string) => {
        const time = Number(result);

        if (time <= pollIntervalSeconds) {
          clearInterval(checkSessionTimeoutInterval);

          reauthenticationTimeout = setTimeout(() => {
            console.log('Reauthenticating!');
            redirectToLogin();
            clearTimeout(reauthenticationTimeout);
          }, time * 1000);
        }
      })
      .catch((error) => {
        console.debug('Error checking session!', error);
      });
  }

  const events = [
    'reachora:heartbeat:new',
    'reachora:player:progress',
    'keydown',
    'click',
    'scroll',
    'touchstart',
  ];

  events.forEach((event) => {
    window.addEventListener(
      event,
      resetIdleTimer,
      event === 'touchstart' ? { passive: true } : false
    );
  });
});
