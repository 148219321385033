export function sendHeartbeat(): void {
  fetch('/heartbeat/new')
    .then((result) => {
      if (result.ok) {
        console.debug('Heartbeat sent');
      }
    })
    .catch((error) => {
      console.error('Error sending heartbeat', error);
    });
}

export function dispatchHeartbeatEvent(): void {
  const eventInit = { bubbles: true, cancelable: true, detail: {} };
  const event = new CustomEvent('reachora:heartbeat:new', eventInit);

  window.dispatchEvent(event);
}
